<template>
  <div class="text-center">
    <v-dialog v-model="editRouteDialog.visibility" max-width="500" persistent>
      <v-card class="pa-0" flat>
        <!-- Header -->
        <v-toolbar dark color="rgb(47, 207, 156)" dense>
          <v-btn icon disabled></v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>Edit Route</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="SET_EDIT_ROUTE_DIALOG_VISIBILITY(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card flat class="ma-0 py-2 px-6">
          <!-- Chinese (Traditional) Name & Chinese (Simplified) Name-->
          <v-row no-gutters class="mt-2">
            <v-col cols="6" class="pr-2">
              <v-text-field v-model="name" hide-details dense outlined label="Chinese (Traditional)"></v-text-field>
            </v-col>
            <v-col cols="6" class="pl-2">
              <v-text-field v-model="name_cn" outlined hide-details dense label="Chinese (Simplified)"></v-text-field>
            </v-col>
          </v-row>

          <!-- English Name -->
          <v-row no-gutters class="my-4">
            <v-col cols="12">
              <v-text-field v-model="name_en" outlined hide-details dense label="English"></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters class="my-4">
            <v-select v-model="service_type_id" outlined dense hide-details :items="menuSetting.service_type_list" item-text="prefix" item-value="service_type_id"></v-select>
          </v-row>

          <!-- Action Button -->
          <v-row no-gutters class="pb-4 mt-4">
            <v-col cols="6" class="pr-2">
              <v-btn block @click="edit">Edit</v-btn>
            </v-col>
            <v-col cols="6" class="pl-2">
              <v-btn block @click="remove">Remove</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'editRouteDialog',
  data() {
    return {
      route_id: null,
      name: null,
      name_cn: null,
      name_en: null,
      service_type_id: null
    }
  },
  computed: {
    ...mapState(['editRouteDialog', 'menuSetting']),
    route() {
      if (this.editRouteDialog.selected_route_data) return this.editRouteDialog.selected_route_data
      else return { name: '', name_en: '', name_cn: '', service_type_id: null }
    }
  },
  methods: {
    ...mapMutations(['SET_EDIT_ROUTE_DIALOG_VISIBILITY']),
    ...mapActions(['updateRoute', 'deleteRoute']),
    edit() {
      this.updateRoute({
        params: {
          route_id: this.route_id,
          name: this.name,
          name_cn: this.name_cn,
          name_en: this.name_en,
          service_type_id: this.service_type_id
        }
      })
    },
    remove() {
      this.deleteRoute({
        params: {
          route_id: this.route_id
        }
      })
    }
  },
  watch: {
    'editRouteDialog.visibility'() {
      if (this.editRouteDialog.visibility) {
        this.route_id = this.route.route_id
        this.name = this.route.name
        this.name_cn = this.route.name_cn
        this.name_en = this.route.name_en
        this.service_type_id = this.route.service_type_id
      } else {
        this.route_id = null
        this.name = null
        this.name_cn = null
        this.name_en = null
        this.service_type_id = null
      }
    }
  }
}
</script>

<style></style>
