<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Level 1 Menu -->
        <v-row justify="center">
          <v-slide-group v-model="level_1_menu_selected" show-arrows center-active>
            <v-slide-item v-for="(route, index) in menuSetting.level_1_menu_data" :key="route.route_id" v-slot="{ active, toggle }">
              <v-card :color="active ? 'rgb(235, 235, 235)' : 'white'" class="ma-2" height="100" width="250" @click="toggle">
                <template slot="progress">
                  <v-progress-linear color="red" indeterminate></v-progress-linear>
                </template>
                <v-scale-transition>
                  <v-icon v-if="active" color="rgb(57, 58, 78)" size="24" style="position: absolute; right: 5px; top: 5px">fas fa-bookmark</v-icon>
                </v-scale-transition>
                <v-card-subtitle class="pb-0">Menu {{ index + 1 }}</v-card-subtitle>
                <v-card-text>
                  <v-row no-gutters class="text-center">
                    <v-col cols="12" class="text-h6" style="color: rgb(57, 58, 78)"> {{ route.name }} </v-col>
                    <v-col cols="12">{{ route.name_en }}</v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-row>

        <v-row justify="center">
          <v-card width="90%" color="transparent" flat>
            <v-row no-gutters>
              <!-- Level 2 Menu -->
              <v-expand-transition>
                <v-col v-if="menuSetting.level_2_menu_data.visibility" cols="7" xs="12" class="px-2 py-2">
                  <v-card>
                    <div style="position: absolute; right: 90px" class="ma-4">
                      <v-btn color="primary" outlined small @click="INIT_EDIT_ROUTE_DIALOG({ from_level: 1, data: getRouteData(level_1_menu_selected) })">
                        <v-icon small left>fas fa-edit</v-icon> EDIT
                      </v-btn>
                    </div>
                    <div style="position: absolute; right: 0" class="ma-4">
                      <v-btn color="primary" outlined small @click="INIT_NEW_ROUTE_DIALOG({ from_level: 2, data: level_1_menu_selected })"> <v-icon small left>fas fa-plus</v-icon> New </v-btn>
                    </div>
                    <!-- Breadcrumbs -->
                    <v-breadcrumbs :items="menuSetting.level_2_menu_data.breadcrumbs">
                      <template v-slot:divider>
                        <v-icon>mdi-chevron-right</v-icon>
                      </template>
                    </v-breadcrumbs>

                    <!-- Menu Table -->
                    <v-data-table class="level2Menu" @click:row="selectLevel2Menu" hide-default-footer :headers="menuSetting.level_2_menu_data.headers" :items="menuSetting.level_2_menu_data.items">
                      <!-- Index -->
                      <template v-slot:[`item.index`]="{ item }">
                        {{ menuSetting.level_2_menu_data.items.indexOf(item) + 1 }}
                      </template>

                      <!-- Queue -->
                      <template v-slot:[`item.queue`]="{ item }">
                        {{ service_type_prefix(item.service_type_id) }}
                      </template>

                      <!-- Edit -->
                      <template v-slot:[`item.edit`]="{ item }">
                        <v-btn color="primary" outlined small @click="INIT_EDIT_ROUTE_DIALOG({ from_level: 2, data: item })">
                          <v-icon small>far fa-edit</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-expand-transition>

              <!-- Level 3 Menu -->
              <v-slide-x-transition>
                <v-col cols="5" xs="12" class="px-2 py-2" v-if="menuSetting.level_3_menu_data.visibility" key="level3Menu">
                  <v-card>
                    <div style="position: absolute; right: 0" class="ma-3">
                      <v-btn color="primary" outlined small @click="INIT_NEW_ROUTE_DIALOG({ from_level: 3, data: level_2_menu_selected })"> <v-icon small left>fas fa-plus</v-icon> New </v-btn>
                    </div>
                    <v-card-subtitle>{{ menuSetting.level_3_menu_data.title }}</v-card-subtitle>
                    <v-data-table class="level2Menu" hide-default-footer :headers="menuSetting.level_3_menu_data.headers" :items="menuSetting.level_3_menu_data.items">
                      <!-- Queue -->
                      <template v-slot:[`item.queue`]="{ item }">
                        {{ service_type_prefix(item.service_type_id) }}
                      </template>

                      <!-- Edit -->
                      <template v-slot:[`item.edit`]="{ item }">
                        <v-btn color="primary" outlined small @click="INIT_EDIT_ROUTE_DIALOG({ from_level: 3, data: item })">
                          <v-icon small>far fa-edit</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-slide-x-transition>
            </v-row>
          </v-card>
        </v-row>
      </v-col>
    </v-row>

    <newRouteDialog />
    <editRouteDialog />
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import newRouteDialog from '@/components/dialogs/newRouteDialog'
import editRouteDialog from '@/components/dialogs/editRouteDialog'

export default {
  name: 'MenuSetting',
  components: { newRouteDialog, editRouteDialog },
  data: () => ({
    level_1_menu_selected: null,
    level_2_menu_selected: null
  }),
  computed: { ...mapState(['menuSetting']) },
  methods: {
    ...mapMutations(['SET_MENU_SETTING_LEVEL_2_MENU_VISIBILITY', 'SET_MENU_SETTING_LEVEL_3_MENU_VISIBILITY', 'INIT_NEW_ROUTE_DIALOG', 'INIT_EDIT_ROUTE_DIALOG']),
    ...mapActions(['fetchServiceTypeList', 'fetchRootLevelRoute', 'fetchLevel2Route', 'fetchLevel3Route']),
    service_type_prefix(service_type_id) {
      return this.menuSetting.service_type_list.find(service_type => service_type.service_type_id === service_type_id).prefix
    },
    selectLevel2Menu(data) {
      this.level_2_menu_selected = data
    },
    getRouteData(index) {
      return this.menuSetting.level_1_menu_data[index]
    }
  },
  watch: {
    // Controller for displaying different levels of menu
    level_1_menu_selected: {
      immediate: true,
      handler() {
        this.SET_MENU_SETTING_LEVEL_2_MENU_VISIBILITY(false)
        this.SET_MENU_SETTING_LEVEL_3_MENU_VISIBILITY(false)
        if (this.level_1_menu_selected !== null && typeof this.level_1_menu_selected !== 'undefined') {
          if (this.menuSetting.level_1_menu_data.length) {
            const route_id = this.menuSetting.level_1_menu_data[this.level_1_menu_selected].route_id
            this.fetchLevel2Route(route_id)
          }
        }
      }
    },
    level_2_menu_selected: {
      handler() {
        this.SET_MENU_SETTING_LEVEL_3_MENU_VISIBILITY(false)
        const route_id = this.level_2_menu_selected.route_id
        this.fetchLevel3Route(route_id)
      }
    }
  },
  mounted() {
    this.fetchServiceTypeList()
    this.fetchRootLevelRoute()

    let autoSelectOnLoad = setInterval(() => {
      if (this.menuSetting.level_1_menu_data.length) {
        this.level_1_menu_selected = 0
        clearInterval(autoSelectOnLoad)
      }
    }, 200)
  }
}
</script>

<style scoped>
.level2Menu >>> tbody tr :hover {
  cursor: pointer;
}
</style>
