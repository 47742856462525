<template>
  <div class="text-center">
    <v-dialog v-model="newRouteDialog.visibility" max-width="500" persistent>
      <v-card class="pa-0" flat>
        <!-- Header -->
        <v-toolbar dark dense color="rgb(47, 207, 156)">
          <v-btn icon disabled></v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>New Route</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="SET_NEW_ROUTE_DIALOG_VISIBILITY(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card flat class="ma-0 py-2 px-6">
          <!-- Chinese (Traditional) Name & Chinese (Simplified) Name-->
          <v-row no-gutters class="mt-2">
            <v-col cols="6" class="pr-2">
              <v-text-field v-model="name" hide-details dense outlined label="Chinese (Traditional)"></v-text-field>
            </v-col>
            <v-col cols="6" class="pl-2">
              <v-text-field v-model="name_cn" outlined hide-details dense label="Chinese (Simplified)"></v-text-field>
            </v-col>
          </v-row>

          <!-- English Name -->
          <v-row no-gutters class="my-4">
            <v-col cols="12">
              <v-text-field v-model="name_en" outlined hide-details dense label="English"></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters class="my-4">
            <v-select
              v-model="service_type_id"
              :items="menuSetting.service_type_list"
              item-text="service_type"
              item-value="service_type_id"
              outlined
              dense
              hide-details
              :label="service_type_label"
            ></v-select>
          </v-row>

          <!-- Action Button -->
          <v-row no-gutters class="pb-4 mt-4">
            <v-col cols="6" class="pr-2">
              <v-btn block @click="create">Create</v-btn>
            </v-col>
            <v-col cols="6" class="pl-2">
              <v-btn block @click="reset">Reset</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'newRouteDialog',
  data() {
    return {
      name: null,
      name_cn: null,
      name_en: null,
      service_type_id: null
    }
  },
  computed: {
    ...mapState(['newRouteDialog', 'menuSetting']),
    service_type_label() {
      if (this.newRouteDialog.from_level === 3) {
        return 'Service Type'
      }
      return 'Service Type (Optional)'
    }
  },
  methods: {
    ...mapActions(['createNewRoute']),
    ...mapMutations(['SET_NEW_ROUTE_DIALOG_VISIBILITY', 'SET_SNACKBAR']),
    create() {
      // Get Parent ID
      const create_from_level = this.newRouteDialog.from_level
      const from_level_data = this.newRouteDialog.from_level_data
      const parent_id = create_from_level === 2 ? this.menuSetting.level_1_menu_data[from_level_data].route_id : from_level_data.route_id
      if (this.name && this.name_cn && this.name_en) {
        if (create_from_level === 3) {
          if (!this.service_type_id) {
            this.SET_SNACKBAR({ status: true, color: 'error', text: 'Service Type is required for level 3 route', icon: 'close', timeout: '2000' })
            return
          }
        }
        this.createNewRoute({
          params: {
            parent_id: parent_id,
            name: this.name,
            name_cn: this.name_cn,
            name_en: this.name_en,
            service_type_id: this.service_type_id
          }
        })
      } else {
        this.SET_SNACKBAR({ status: true, color: 'error', text: 'Please enter all required fields', icon: 'close', timeout: '2000' })
      }
    },
    reset() {
      this.name = null
      this.name_cn = null
      this.name_en = null
      this.service_type_id = null
    }
  },
  watch: {
    'newRouteDialog.visibility'() {
      if (!this.newRouteDialog.visibility) {
        this.reset()
      }
    }
  }
}
</script>

<style></style>
